
import { defineComponent, ref, onMounted, computed } from "vue";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import AddCustomerModal from "@/components/modals/forms/AddCustomerModal.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { statusState } from "@/core/helpers/converters";
import { Actions } from "@/store/enums/StoreEnums";
import { Statuses } from "@/store/enums/StatusEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { cardCodes, cardTypeCodes, findIconCard } from "@/store/enums/cardCodes";
import { replaceMonth } from "@/store/enums/months";
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useI18n } from "vue-i18n";
import store from "@/store";

interface ICustomer {
  id: number;
  name: string;
  email: string;
  company: string;
  phone?: string;
  group?: string;
  count?: string;
  expired?: string;
  status?: string;
  state?: string;
  payment: {
    icon: string;
    ccnumber: string;
    label: string;
  };
  date: string;
}

export default defineComponent({
  name: "customers-listing",
  components: {
    Datatable,
    AddCustomerModal,
  },
  setup() {
    const checkedCustomers = ref([]);
    const store = useStore();
    const router = useRouter();
    const onlyDebtors = ref<boolean>(false);
    const customers = ref<Array<ICustomer>>([]);
    const { t } = useI18n();

    const now = moment(new Date());
    const tableHeader = ref([
      {
        name: t("countOrders"),
        key: "count",
        sortable: true,
      },
      {
        name: t("group"),
        key: "group",
        sortable: true,
      },
      {
        name: t("firstName"),
        key: "name",
        sortable: true,
      },
      {
        name: t("email"),
        key: "email",
        sortable: true,
      },
      {
        name: t("phoneNumber"),
        key: "phone",
        sortable: true,
      },
      {
        name: t("expired"),
        key: "expired",
        sortable: true,
      },
      {
        name: t("status"),
        key: "status",
        sortingField: "payment.label",
        sortable: true,
      },
      {
        name: t("actions"),
        key: "actions",
      },
    ]);

    const searchItem = (item, searchValue) => {
      return item.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase());
    };

    const getCustomers = computed(() => {
      const data = store.getters.getSubscribersTableData;

      let results: Array<ICustomer> = [];

      data
        .map((item) => {
          const img = item?.card_first6
            ? findIconCard(item.card_first6[0], cardCodes)
            : "";
          let duration = moment.duration(moment(item.date_expired_debtor).diff(now));
          let paidOrders = item?.orders.reduce((total, order) => {
                console.log('order.status - ', order.status)
                let result = order.status === 'succeeded' ? 1 : 0
                console.log('order.status result - ', result)
                return total + result
              }, 0);
          console.log('paidOrders - ', paidOrders)
          return {
            id: item?._id ? item._id : "",
            count: paidOrders,
            name: item?.user?.fname ? item.user.fname : "",
            email: item?.user?.email ? item.user.email : "",
            phone: item?.user?.phone ? item.user.phone : "",
            group: item?.group_name ? item.group_name : "",
            icon: img,
            status: item?.status ? item.status : "",
            state: statusState(item.status),
            expired: item?.date_expired ? moment(item.date_expired).format("MMM DD, YYYY") : "",
            date_expired_debtor: item?.date_expired_debtor
              ? duration.asDays().toFixed(0)
              : "1",
            payment: {
              ccnumber: item?.card_last4 ? item.card_last4 : "",
              firstnumber: item?.card_first6 ? item.card_first6 : "",
            },
          };
        })
        .forEach((item) => {
          if (
            searchItem(item.email, search.value) ||
            searchItem(item.name, search.value) ||
            searchItem(String(item.phone), search.value) ||
            searchItem(item.group, search.value)
          ) {
            if (onlyDebtors.value) {
              if (item.status === "debtor") {
                results.push(item);
              }
            } else {
              results.push(item);
            }
          }
        });
      return results ? results : [];
    });

    const initCustomers = ref<Array<ICustomer>>([]);

    onMounted(() => {
      MenuComponent.reinitialization();

      initCustomers.value.splice(0, customers.value.length, ...customers.value);

      setCurrentPageBreadcrumbs( t("subscriptions"), []);
    });

    const goDetails = (id) => {
      router.push({ name: "subscription", params: { id: id } });
    };

    const deleteFewCustomers = () => {
      checkedCustomers.value.forEach((item) => {
        deleteCustomer(item);
      });
      checkedCustomers.value.length = 0;
    };

    const deleteCustomer = (id) => {
      for (let i = 0; i < customers.value.length; i++) {
        if (customers.value[i].id === id) {
          customers.value.splice(i, 1);
        }
      }
    };

    /**
     * Change subscription status to locked
     */
    const pauseCustomer = (id) => {

      Swal.fire({
        title: t('questionPause'),
        text: t('alertPause'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: t('yesPause'),
        cancelButtonText: t('noPause'),
      }).then((result) => {
        if (result.isConfirmed) {

          const data = {
            subscription_id: id
          };

          store
              .dispatch(Actions.SUBSCRIPTION_PUT_PAUSE, data)
              .then(() => {
                store.dispatch(Actions.GET_SUBSCRIBERS_TABLE)
                    .then(() => {
                      Swal.fire(
                          t('successPause'),
                          '',
                          'success'
                      );
                      // TODO: change item status
                    })
              })
              .catch(() => {
                Swal.fire({
                  text: 'Error',
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: t("tryAgain"),
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-danger",
                  },
                });
              })

        }
      });

    };

    /**
     * Change subscription status to active if status is locked
     */
    const pauseOffCustomer = (id) => {
      Swal.fire({
        title: t('questionPause'),
        text: t('alertPauseOff'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: t('pauseStart'),
        cancelButtonText: t('noPause'),
      }).then((result) => {
        if (result.isConfirmed) {

          const data = {
            subscription_id: id
          };

          store
              .dispatch(Actions.SUBSCRIPTION_PUT_PAUSE_OFF, data)
              .then(() => {
                store.dispatch(Actions.GET_SUBSCRIBERS_TABLE)
                    .then(() => {
                      Swal.fire(
                          t('successPauseOff'),
                          '',
                          'success'
                      );
                      // TODO: change item status
                    })

              })
              .catch(() => {
                Swal.fire({
                  text: 'Error',
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: t("tryAgain"),
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-danger",
                  },
                });
              })

        }
      });
    };

    const mailto = (email) => {
      return "mailto:" + email;
    };

    const search = ref<string>("");

    return {
      customers,
      getCustomers,
      Statuses,
      tableHeader,
      deleteCustomer,
      pauseCustomer,
      pauseOffCustomer,
      mailto,
      search,
      onlyDebtors,
      checkedCustomers,
      deleteFewCustomers,
      goDetails,
      t,
    };
  },
});
