<template>
  <SubscriptionListing v-if="currentRouteName === 'subscriptions'"/>
  <router-view />
  <router-view name="subscribersOne"/>
</template>

<script>
import SubscriptionListing from '@/views/apps/customers/SubscriptionListing.vue'
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";

export default {
  name: "Subscribers",
  components: {
    SubscriptionListing
  },
  created() {
    // fetch all users from api
    store.dispatch(Actions.GET_SUBSCRIBERS_TABLE);
  },
  beforeRouteUpdate: (to, from, next) => {
    store.dispatch(Actions.GET_SUBSCRIBERS_TABLE)
        .then(() => next())

  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    }
  },
}
</script>

<style scoped>

</style>